<script setup lang="ts">
import {
  DsKebabMenu,
  DsButton,
  DsIcon,
  DS_ICONS,
  DsBreadCrumbs,
} from '@dreamshaper/ds-ui'
import HeaderAutocomplete from './HeaderAutocomplete.vue'
import { useBreadcrumbs } from '@/composables/useBreadcrumbs'

const { breadcrumbs } = useBreadcrumbs()
const { signOut } = useAuth()

const route = useRoute()

const handleSettingsSelection = (option: {
  label: string
  value: string
}) => {
  if (option.value === 'logout') {
    signOut()
  }
}

const currentRoute = ref(route.path)

const hiddenRoutes = [
  'edit',
  'create',
  'ai-evaluation',
]

const isHiddenRoute = computed(() => hiddenRoutes.some(hiddenRoute =>
  route.name?.includes(hiddenRoute),
))

const shouldRenderAutocomplete = computed(() => {
  const isOrgRoute = route.name?.includes('org-orgId')
  const isCustomerRoute = route.name?.includes('customer-customerId')

  return (isOrgRoute || isCustomerRoute) && !isHiddenRoute.value
})

const pageType = computed<'org' | 'customer' | undefined>(() => {
  if (isHiddenRoute.value) return undefined

  if (route.name?.includes('org')) {
    return 'org'
  }

  return 'customer'
})

watch(
  () => route.path,
  (newPath) => {
    currentRoute.value = newPath
  },
)
</script>

<template>
  <section
    class="header-container flex flex-row w-full items-center justify-between max-w-[62.5rem] pt-[1.25rem] gap-8"
  >
    <div class="w-min whitespace-nowrap">
      <ClientOnly>
        <DsBreadCrumbs
          :items="breadcrumbs"
          :current-route="currentRoute.value"
        />
      </ClientOnly>
    </div>

    <div class="flex w-min justify-end gap-[1.25rem]">
      <HeaderAutocomplete
        v-if="shouldRenderAutocomplete"
        :type="pageType"
      />

      <DsKebabMenu
        v-once
        options-menu-centered-on="end"
        options-menu-open-on="bottom"
        :options="[{ label: 'Logout', value: 'logout' }]"
        optionKey="value"
        teleport-to="#settings-root"
        @on-select="handleSettingsSelection"
      >
        <template #default>
          <DsButton
            rounded
            color="tertiary"
          >
            <template #onlyIcon>
              <DsIcon
                type="svg"
                :icon="DS_ICONS.Settings"
              />
            </template>
          </DsButton>
        </template>

        <template #option="{ option }">
          {{ option.label }}
        </template>
      </DsKebabMenu>
    </div>
  </section>
</template>

<style lang="scss">
  .ds-breadcrumbs {
    .ds-breadcrumbs-item {
      .ds-breadcrumbs-path {
        width: max-content;

        @media screen and (min-width: 768px) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 9rem;
        }
      }
    }
  }
</style>
