<script setup lang="ts">
import type { Organization, Customer } from '@dreamshaper/ds-interface'
import { storeToRefs } from 'pinia'

import { DS_ICONS, DsAutocomplete, useDsToast } from '@dreamshaper/ds-ui'
import { useOrganizationStore } from '@/stores/organizationStore'
import { useCustomerStore } from '@/stores/customerStore'

type HeaderAutocompleteProps = {
  type?: 'org' | 'customer'
}

const props = defineProps<HeaderAutocompleteProps>()

const { organizations } = storeToRefs(useOrganizationStore())
const { customers } = storeToRefs(useCustomerStore())

const config = useRuntimeConfig()
const route = useRoute()
const dsInterface = useDSInterface()
const { org, customer } = useUser()
const toasts = useDsToast()

const page = ref<number>(1)
const filteredPage = ref<number>(1)
const searchName = ref<string>('')
const organizationsFiltered = ref<Organization[]>()
const customersFiltered = ref<Customer[]>()

const { data: responseOrganizations, pending: isLoadingOrganizations } = useAsyncData(
  'organizations',
  () =>
    dsInterface.organization().findAll({
      page: searchName.value ? filteredPage.value : page.value,
      per_page: 25,
      search: searchName.value,
      include: ['definitions'],
    }),
  {
    transform: (responseOrganizations) => {
      const organizationData = responseOrganizations?.data
      const totalPages = responseOrganizations?.meta?.last_page || 1

      return { totalPages, organizationData }
    },
    watch: [filteredPage, page, searchName],
  },
)

const { data: responseCustomers, pending: isLoadingCustomers } = useAsyncData(
  'organization-customers',
  () =>
    dsInterface.customer().findAll({
      page: searchName.value ? filteredPage.value : page.value,
      per_page: 25,
      filter: {
        name: searchName.value,
      },
    }),
  {
    transform: (responseCustomers) => {
      const customerData = responseCustomers?.data
      const totalPages = responseCustomers?.meta?.last_page || 1

      return { totalPages, customerData }
    },
    watch: [filteredPage, page, searchName],
  },
)

const organizationOptions = computed(() => {
  if (!organizations.value) return []

  const newOptions: Organization[] = useCloneDeep(organizations.value)

  const orgExistsInTheList = newOptions.find(option => option.id === org.value?.id)

  if (!orgExistsInTheList && org.value) {
    newOptions.unshift(org.value)
  }

  return newOptions || []
})

const customerOptions = computed(() => {
  if (!customers.value) return []

  const newOptions: Customer[] = customers.value

  const customerExistsInTheList = newOptions.find(option => option.id === customer.value?.id)

  if (!customerExistsInTheList && customer.value) {
    newOptions.unshift(customer.value)
  }

  return newOptions || []
})

const organizationOptionsFiltered = computed(() => {
  if (props.type !== 'org' || !searchName.value || !organizationsFiltered.value) {
    return undefined
  }

  const newOptions: Organization[] = useCloneDeep(organizationsFiltered.value)

  return newOptions || undefined
})

const customerOptionsFiltered = computed(() => {
  if (props.type !== 'customer' || !searchName.value || !customersFiltered.value) {
    return undefined
  }

  return customersFiltered.value || undefined
})

const onChangeSelectedOrg = async (newSelectedItem: Organization) => {
  if (!newSelectedItem) return

  org.value = newSelectedItem

  if (route.path !== config.public.entryPage) {
    const urlParts = route.path.split('/')

    for (let i = 0; i < urlParts.length; i++) {
      if (urlParts[i] === 'org') {
        urlParts[i + 1] = newSelectedItem.id.toString()

        break
      }
    }

    if (urlParts.includes('builder') && !newSelectedItem.multipath_support) {
      navigateTo(`/org/${newSelectedItem.id}/home`, {
        replace: false,
        redirectCode: 301,
      })

      return
    }

    if (urlParts.includes('create')) {
      urlParts.pop()
    }

    if (urlParts.includes('edit') || urlParts.includes('info')) {
      urlParts.splice(-2)
    }

    const lastUrlParts = useCloneDeep(urlParts).pop()

    if (!Number.isNaN(Number(lastUrlParts)) && typeof Number(lastUrlParts) === 'number') {
      urlParts.pop()
    }

    const newUrl = urlParts.join('/')

    navigateTo(newUrl, {
      replace: false,
      redirectCode: 301,
    })

    return
  }

  window.location.reload()
}

const onChangeSelectedCustomer = async (newSelectedItem: Customer) => {
  if (!newSelectedItem) return

  customer.value = newSelectedItem

  if (route.path !== config.public.entryPage) {
    const urlParts = route.path.split('/')

    for (let i = 0; i < urlParts.length; i++) {
      if (urlParts[i] === 'customer') {
        urlParts[i + 1] = newSelectedItem.id.toString()

        break
      }
    }

    if (urlParts.includes('create')) {
      urlParts.pop()
    }

    if (urlParts.includes('edit') || urlParts.includes('info')) {
      urlParts.splice(-2)
    }

    const lastUrlParts = useCloneDeep(urlParts).pop()

    if (!Number.isNaN(Number(lastUrlParts)) && typeof Number(lastUrlParts) === 'number') {
      urlParts.pop()
    }

    const newUrl = urlParts.join('/')

    navigateTo(newUrl, {
      replace: false,
      redirectCode: 301,
    })

    return
  }

  window.location.reload()
}

const onChangeSearchName = useDebounce((value: string) => {
  if (!value) return

  searchName.value = value
}, 600)

const onEndScrollReachedOnOrganization = () => {
  if (
    !isLoadingOrganizations.value
    && filteredPage.value < responseOrganizations.value?.totalPages
    && searchName.value
  ) {
    filteredPage.value++

    return
  }

  if (!isLoadingOrganizations.value && page.value < responseOrganizations.value?.totalPages) {
    page.value++
  }
}

const onEndScrollReachedOnCustomer = () => {
  if (
    !isLoadingCustomers.value
    && filteredPage.value < responseCustomers.value?.totalPages
    && searchName.value
  ) {
    filteredPage.value++

    return
  }

  if (!isLoadingCustomers.value && page.value < responseCustomers.value?.totalPages) {
    page.value++
  }
}

const onEndScrollReached = () => {
  if (props.type === 'org') {
    onEndScrollReachedOnOrganization()
  } else {
    onEndScrollReachedOnCustomer()
  }
}

const changeOrganizationTo = async (organizationId: number) => {
  const { data, statusCode } = await dsInterface.organization().findOne({
    id: organizationId,
  })
  if (data && statusCode === 200) {
    onChangeSelectedOrg(data)
  } else {
    toasts?.add({
      summary: 'Error - Organization',
      detail: 'Organization not found',
      severity: 'error',
      duration: 10000,
      position: 'bottom-right',
      closable: true,
    })
  }
}

const changeCustomerTo = async (customerId: number) => {
  const { data, statusCode } = await dsInterface.customer().findOne({
    customerId,
  })

  if (data && statusCode === 200) {
    customer.value = data
  }
}

const getLabel = computed(() => {
  return props.type === 'org' ? 'Select Organization' : 'Select Customer'
})

const getOptions = computed(() => {
  if (props.type === 'org') {
    return organizationOptionsFiltered.value || organizationOptions.value
  }

  return customerOptionsFiltered.value || customerOptions.value
})

watch(
  () => responseOrganizations.value?.organizationData,
  (newOrganizations) => {
    if (searchName.value) {
      if (
        organizationsFiltered.value
        && organizationsFiltered.value?.length > 0
        && newOrganizations
      ) {
        const filteredOrganizations = newOrganizations.filter(
          organization => !organizationsFiltered.value?.some(org => org.id === organization.id),
        )

        organizationsFiltered.value.push(...filteredOrganizations)
      } else {
        organizationsFiltered.value = newOrganizations
      }

      return
    }

    if (organizations.value && organizations.value?.length > 0 && newOrganizations) {
      const filteredOrganizations = newOrganizations.filter(
        organization => !organizations.value?.some(org => org.id === organization.id),
      )

      organizations.value.push(...filteredOrganizations)
    } else {
      organizations.value = newOrganizations
    }

    organizationsFiltered.value = undefined
    filteredPage.value = 1
  },
)

watch(
  () => responseCustomers.value?.customerData,
  (newCustomers) => {
    if (searchName.value) {
      if (customersFiltered.value && customersFiltered.value?.length > 0 && newCustomers) {
        const filteredCustomers = newCustomers.filter(
          customer => !customersFiltered.value?.some(cust => cust.id === customer.id),
        )

        customersFiltered.value.push(...filteredCustomers)
      } else {
        customersFiltered.value = newCustomers
      }

      return
    }

    if (customers.value && customers.value?.length > 0 && newCustomers) {
      const filteredCustomers = newCustomers.filter(
        customer => !customers.value?.some(cust => cust.id === customer.id),
      )

      customers.value.push(...filteredCustomers)
    } else {
      customers.value = newCustomers
    }

    customersFiltered.value = undefined
    filteredPage.value = 1
  },
)

watch(
  () => route?.params,
  (newRouteParams) => {
    if (newRouteParams?.orgId && org.value?.id !== Number(newRouteParams.orgId)) {
      changeOrganizationTo(Number(newRouteParams.orgId))
    }

    if (newRouteParams?.customerId && customer.value?.id !== Number(newRouteParams.customerId)) {
      changeCustomerTo(Number(newRouteParams.customerId))
    }
  },
)

onBeforeMount(async () => {
  if (
    !route.name?.toString().includes('user')
    && route.fullPath !== '/org'
    && route.params?.orgId
    && org.value?.id !== Number(route.params.orgId)
  ) {
    const regexOrgId = /^\/org\/([^/]+)\/?/

    const match = window.location.pathname.match(regexOrgId)

    if (match) {
      changeOrganizationTo(Number(match[1]))
    }
  }

  if (
    !route.name?.toString().includes('user')
    && route.fullPath !== '/customer'
    && route.params?.customerId
    && customer.value?.id !== Number(route.params.customerId)
  ) {
    const regexCustomerId = /^\/customer\/([^/]+)\/?/

    const match = window.location.pathname.match(regexCustomerId)

    if (match) {
      changeCustomerTo(Number(match[1]))
    }
  }

  if (
    !route.name?.toString().includes('user')
    && route.fullPath !== '/org'
    && !responseOrganizations.value?.organizationData
  ) {
    await refreshNuxtData('organizations')
  }

  if (
    !route.name?.toString().includes('user')
    && route.fullPath !== '/customer'
    && !responseCustomers.value?.customerData
  ) {
    await refreshNuxtData('organization-customers')
  }
})
</script>

<template>
  <DsAutocomplete
    :model-value="props.type === 'org' ? org : customer || undefined"
    :label="getLabel"
    name="autocomplete_header_org_and_customer"
    optionKey="id"
    option-label="name"
    :options="getOptions"
    :propertiesToSearch="props.type === 'org' ? ['name', 'subdomain'] : ['name']"
    :right-icon="DS_ICONS.ArrowDownThin"
    :loadingOptions="props.type === 'org' ? isLoadingOrganizations : isLoadingCustomers"
    :show-label-with-value-selected="true"
    keep-value-even-on-backspace
    options-two-labels
    :margin-on-bottom="false"
    @on-change="
      (value) =>
        props.type === 'org' ? onChangeSelectedOrg(value) : onChangeSelectedCustomer(value)
    "
    @on-end-scroll-reached="onEndScrollReached"
    @on-search-change="onChangeSearchName"
  >
    <template #label="{ option }">
      {{ option.name }}
    </template>

    <template
      v-if="props.type === 'org'"
      #sublabel="{ option }"
    >
      {{ option.subdomain }}
    </template>

    <template #selectedLabel="{ option }">
      {{ option.name }}
    </template>
  </DsAutocomplete>
</template>

<style lang="scss">
@import "./HeaderAutocomplete.scss";
</style>
