import { useSessionStorage } from '@vueuse/core'

const isMathPatternPath = (
  pathA: string,
  pathB: string,
  additionalPath?: string,
) => {
  const partsA = pathA.split('/')
  const partsB = pathB.split('/')

  if (additionalPath) partsB.push(additionalPath)

  if (partsA.length !== partsB.length) return false

  const isMatch = partsA.every((part: string, i: number) => {
    return part === partsB[i] || part.startsWith(':')
  })

  return isMatch
}

export const useBreadcrumbs = () => {
  const { org } = useUser()
  const router = useRouter()
  const route = useRoute()
  const routes = router.getRoutes()

  const breadcrumbs: Ref<Array<{ name: string, path?: string }>> = ref([])
  const idsRequested = useSessionStorage<Record<string, string>>('breadcrumbs-ids-requested', {})
  const lastVisitedRouteName = ref('')
  const lastVisitedRoutePath = ref('')

  const infoRoutes = ['org-orgId-project-projectId-info', 'user-userId-info']
  const editRoutes = ['org-orgId-project-projectId-edit', 'user-userId-edit']

  const getCustomRouteFlow = (paths: { name: string, path: string }[]) => {
    if (paths.length === 0) return paths

    if (editRoutes.includes(route.name) && infoRoutes.includes(lastVisitedRouteName.value)) {
      const pathIndex = paths.findIndex(path => path.path === lastVisitedRoutePath.value)

      if (pathIndex && pathIndex !== -1) {
        paths.splice(pathIndex, 1)
      }

      paths.push({
        path: lastVisitedRoutePath.value,
        name: lastVisitedRoutePath.value.split('/').pop() || lastVisitedRoutePath.value,
      })
    }

    if (lastVisitedRouteName.value === 'org-orgId-builder-path-pathId-module-list' && route.name === 'org-orgId-builder-path-pathId-module-moduleId-page-list') {
      const pathIndex = paths.findIndex(path => path.path === lastVisitedRoutePath.value)

      if (pathIndex && pathIndex !== -1) {
        paths.splice(pathIndex, 1)
      }

      const modulePath = lastVisitedRoutePath.value.split('/')

      paths.push({
        path: lastVisitedRoutePath.value,
        name: modulePath[modulePath.length - 2] && modulePath[modulePath.length - 1] ? `${modulePath[modulePath.length - 2]} ${modulePath[modulePath.length - 1]}` : lastVisitedRoutePath.value,
      })
    }

    return paths
  }

  const getBreadcrumbs = (currRoute: string): { name: string, path: string }[] => {
    if (currRoute === '') return []

    let paths = getBreadcrumbs(
      currRoute.slice(0, currRoute.lastIndexOf('/')),
    )

    let founds = routes.filter((r: any) =>
      isMathPatternPath(r.path, currRoute),
    )

    const intermediaryRoutes = ['home', 'list']

    if (founds.length === 0) {
      for (const intermediaryRoute of intermediaryRoutes) {
        if (founds.length > 0) break

        founds = routes.filter((r: any) =>
          isMathPatternPath(r.path, currRoute, intermediaryRoute),
        )

        if (founds.length > 0) {
          currRoute = `${currRoute}/${intermediaryRoute}`
        }
      }
    }

    if (paths.find(p => p.path === currRoute)) return paths

    const matchRoute
      = founds.length > 1
        ? founds.find((r: any) => r.path === currRoute)
        : founds[0]

    let name = currRoute.split('/').pop() || currRoute

    if (!matchRoute || !isNaN(parseInt(name))) {
      return paths
    }

    if (matchRoute.name === 'orgId') {
      return []
    }

    paths = getCustomRouteFlow(paths)

    const equivalentRoutes = [
      {
        routeToBeReplaced: /org\/\d+\/home$/ui,
        routeEquivalent: /org\/\d+\/builder$/ui,
      },
    ]

    for (const equivalentRoute of equivalentRoutes) {
      if (currRoute.match(equivalentRoute.routeEquivalent)) {
        const path = paths.find(p => p.path?.match(equivalentRoute.routeToBeReplaced))

        if (path) {
          name = path.name
          paths = paths.filter(p => p.path !== path.path)
        }
      }
    }

    return [
      ...paths,
      {
        path: currRoute,
        name: name,
      },
    ]
  }

  watch([() => route.name, () => route.path], ([newRouteName, _newRoutePath], [oldRouteName, oldRoutePath]) => {
    if (newRouteName === oldRouteName) return

    lastVisitedRouteName.value = oldRouteName
    lastVisitedRoutePath.value = oldRoutePath
  })

  watch(
    () =>
      route.path,
    (newPath) => {
      if (newPath === '/') return

      breadcrumbs.value = getBreadcrumbs(newPath)
    },
    {
      immediate: true,
    },
  )

  watch(org, async (newOrg) => {
    if (newOrg) {
      idsRequested.value = {}
    }
  })

  return {
    breadcrumbs,
  }
}
