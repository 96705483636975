<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'
import { DsAccordion, DsIcon, DS_ICONS, DsChip, DsTypography } from '@dreamshaper/ds-ui'
import type { DsSidebarComponent, DsSidebarStructure } from './DsSidebar.types'
import { DsSidebarProps } from './DsSidebar.types'

const props = defineProps(DsSidebarProps)

const itemsExpanded = ref<string[]>([])
const isCollapsed = ref(false)

const toggleOpen = (item: DsSidebarStructure) => {
  if (itemsExpanded.value.includes(item.label.toLowerCase())) {
    itemsExpanded.value = itemsExpanded.value.filter(
      (label: string) => label !== item.label.toLowerCase(),
    )
  } else {
    itemsExpanded.value.push(item.label.toLowerCase())
  }
}

const envName = computed(() => {
  const envName = []

  if (props.type === 'production') {
    envName.push('Prod Environment')
  }

  if (props.type === 'lex') {
    envName.push('LEX')
  }

  if (props.type === 'development') {
    envName.push('Dev Environment')
  }

  if (props.type === 'sandbox') {
    envName.push('Sandbox')
  }

  if (props.type === 'report') {
    envName.push('Report')
  }

  return envName
})

const populateInitialItemsExpanded = (item: DsSidebarStructure) => {
  if (item.currentPage) {
    if (item.childs) {
      const hasCurrentPage = item.childs.some((child) => {
        if (child.currentPage) {
          itemsExpanded.value.push(child.label.toLowerCase())
        }
      })

      if (hasCurrentPage) {
        itemsExpanded.value.push(item.label.toLowerCase())
      }
    } else {
      itemsExpanded.value.push(item.label.toLowerCase())
    }
  }

  if (item.childs) {
    const hasCurrentPage = item.childs.some(
      child => child.currentPage || populateInitialItemsExpanded(child),
    )
    if (hasCurrentPage) {
      itemsExpanded.value.push(item.label.toLowerCase())

      return
    }
  }

  return false
}

const getAccordionClass = (label: string) => {
  return itemsExpanded.value.includes(label) ? 'expanded' : ''
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text
  } else {
    return text.substring(0, maxLength) + '...'
  }
}

const getBadgeColor = (color: string | undefined): 'filter' | 'success' | 'warning' | 'gray' | 'green' | 'navy' | 'salmon' | 'mint' | 'blue-base' | 'mint-dark' | 'lilac' | 'blue-royal' | undefined => {
  const validColors = ['filter', 'success', 'warning', 'gray', 'green', 'navy', 'salmon', 'mint', 'blue-base', 'mint-dark', 'lilac', 'blue-royal']
  return validColors.includes(color as string) ? (color as any) : undefined
}

watch(() => props.structure, (newStructure) => {
  if (newStructure && newStructure.length > 0) {
    itemsExpanded.value = []

    newStructure.forEach(item => populateInitialItemsExpanded(item))
  }
})

onMounted(() => {
  if (props.structure && props.structure.length > 0) {
    itemsExpanded.value = []

    props.structure.forEach(item => populateInitialItemsExpanded(item))
  }
})
const toggleSidebar = () => {
  isCollapsed.value = !isCollapsed.value
}

watch(itemsExpanded, (newitemsExpanded) => {
  itemsExpanded.value = newitemsExpanded
})
</script>

<template>
  <div class="ds-navigation">
    <div :class="['sidebar', props.type, { collapsed: isCollapsed }]">
      <div class="header">
        <div>
          <DsIcon
            type="svg"
            :icon="props.headerIcon || DS_ICONS.SettingsTwoWheels"
          />
        </div>

        <div>
          <p class="admin-name">
            {{ props.name }}
          </p>

          <p
            v-if="props.showEnvName"
            class="env-name"
          >
            {{ envName.toString() }}
          </p>
        </div>
      </div>

      <slot />

      <div class="org-name">
        <p>{{ props.orgName }}</p>
      </div>

      <nav :class="{ 'pt-[3.125rem]': !isCollapsed }">
        <template
          v-for="(item, index) in props.structure"
          :key="index"
        >
          <component
            :is="item.component as DsSidebarComponent"
            v-if="item.component"
            class="ds-btn"
            :class="[props.type, item.currentPage ? 'active' : '']"
            v-bind="{ ...item.componentProps }"
          >
            <DsIcon
              v-if="item.icon && !isCollapsed"
              type="svg"
              :icon="item.icon"
            />

            <DsTypography
              v-if="!isCollapsed"
              variant="label-m"
              class="navigation-text"
            >
              {{ truncateText(item.label, 21) }}

              <div
                v-if="item.badgeText"
                class="badge"
              >
                <DsChip
                  filled
                  shape="square"
                  :color="getBadgeColor(item.badgeColor)"
                >
                  <DsTypography variant="label-s">
                    {{ item.badgeText }}
                  </DsTypography>
                </DsChip>
              </div>
            </DsTypography>

            <span v-else>{{ index + 1 }}</span>
          </component>

          <DsAccordion
            v-else
            :expanded="itemsExpanded.includes(item.label.toLowerCase()) || item.currentPage"
            arrowIcon="end"
            :size="40"
            :alwaysExpanded="false"
            :selected="item.currentPage"
            :classContent="props.type"
            :class="`${item.label.toLowerCase()}-accordion-sidebar ${props.type} ${getAccordionClass(item.label)}`"
            @update:expanded="() => toggleOpen(item)"
          >
            <template #title>
              <span v-if="!isCollapsed">{{ truncateText(item.label, 18) }}</span>
              <span v-else>{{ index + 1 }}</span>
            </template>

            <template #content>
              <template
                v-for="(child, childIndex) in item.childs"
                :key="childIndex"
              >
                <component
                  :is="child.component as DsSidebarComponent"
                  v-if="child.component"
                  class="ds-btn"
                  :class="[props.type, child.currentPage ? 'active' : '']"
                  v-bind="{ ...child.componentProps }"
                >
                  <DsIcon
                    v-if="child.icon"
                    type="svg"
                    :icon="child.icon"
                  />

                  <DsTypography
                    v-if="!isCollapsed"
                    variant="label-m"
                    class="navigation-text"
                  >
                    {{ truncateText(child.label, 18) }}

                    <div
                      v-if="child.badgeText"
                      class="badge"
                    >
                      <DsChip
                        filled
                        shape="square"
                        :color="getBadgeColor(child.badgeColor)"
                      >
                        <DsTypography variant="label-s">
                          {{ child.badgeText }}
                        </DsTypography>
                      </DsChip>
                    </div>
                  </DsTypography>

                  <span v-else>{{ childIndex + 1 }}</span>
                </component>

                <DsAccordion
                  v-else
                  :expanded="itemsExpanded.includes(child.label.toLowerCase())"
                  arrowIcon="end"
                  :size="40"
                  :alwaysExpanded="false"
                  :selected="child.currentPage"
                  :classContent="props.type"
                  :class="`${child.label.toLowerCase()}-accordion-sidebar ${props.type} ${getAccordionClass(child.label)}`"
                  @update:expanded="() => toggleOpen(child)"
                >
                  <template #title>
                    <span v-if="!isCollapsed">{{ child.label }}</span>
                    <span v-else>{{ childIndex + 1 }}</span>
                  </template>

                  <template #content>
                    <template
                      v-for="(grandchild, grandchildIndex) in child.childs"
                      :key="grandchildIndex"
                    >
                      <component
                        :is="grandchild.component as DsSidebarComponent"
                        class="ds-btn"
                        :class="[props.type, grandchild.currentPage ? 'active' : '']"
                        v-bind="{ ...grandchild.componentProps }"
                      >
                        <DsIcon
                          v-if="grandchild.icon"
                          type="svg"
                          :icon="grandchild.icon"
                        />

                        <DsTypography
                          v-if="!isCollapsed"
                          variant="label-m"
                          class="navigation-text"
                        >
                          {{ truncateText(grandchild.label, 16) }}

                          <div
                            v-if="grandchild.badgeText"
                            class="badge"
                          >
                            <DsChip
                              filled
                              shape="square"
                              :color="getBadgeColor(grandchild.badgeColor)"
                            >
                              <DsTypography variant="label-s">
                                {{ grandchild.badgeText }}
                              </DsTypography>
                            </DsChip>
                          </div>
                        </DsTypography>

                        <span v-else>{{ grandchildIndex + 1 }}</span>
                      </component>
                    </template>
                  </template>
                </DsAccordion>
              </template>
            </template>
          </DsAccordion>
        </template>
      </nav>

      <div
        v-if="props.type === 'report'"
        class="toggle-button"
        :class="{ 'toggle-button-is-collapsed': isCollapsed }"
      >
        <DsButton
          color="tertiary"
          @click="toggleSidebar"
        >
          <template #onlyIcon>
            <DsIcon
              type="svg"
              :icon="isCollapsed ? DS_ICONS.ArrowRight : DS_ICONS.ArrowLeft"
              fill="var(--ds-blue-base)"
            />
          </template>
        </DsButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import './DsSidebar.scss';
</style>
