import type { PropType } from 'vue'
import type { DS_ICONS } from '@dreamshaper/ds-ui'

export enum DsSidebarEnv {
  PROD = 'production',
  LEX = 'lex',
  DEV = 'development',
  SANDBOX = 'sandbox',
  REPORT = 'report',
}

export enum DsSidebarComponent {
  NUXTLINK = 'NuxtLink',
  ANCHOR = 'a',
}

export enum DsBadgePosition {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export interface DsSidebarComponentProps {
  href?: string
  to?: string
}

export interface DsSidebarStructure {
  label: string
  icon?: string
  childs: DsSidebarStructure[]
  component?: DsSidebarComponent
  componentProps?: {
    type: DsSidebarComponentProps
  }
  badgeText?: string
  badgeColor?: string
  currentPage?: boolean
  parent?: DsSidebarStructure | null
}

export const DsSidebarProps = {
  type: {
    type: String as PropType<`${DsSidebarEnv}`>,
    validator: (val: DsSidebarEnv) => {
      return Object.values(DsSidebarEnv).includes(val)
    },
    required: true,
    default: DsSidebarEnv.PROD,
  },
  name: {
    type: String,
    required: true,
    default: null,
  },
  showEnvName: {
    type: Boolean,
    required: false,
    default: true,
  },
  headerIcon: {
    type: String as PropType<`${keyof typeof DS_ICONS}` | string>,
    required: false,
    default: null,
  },
  structure: {
    type: Array as PropType<DsSidebarStructure[]>,
    required: true,
    default: () => [],
  },
  orgName: {
    type: String,
    required: false,
    default: null,
  },
}
