import { ref } from 'vue'
import { defineStore } from 'pinia'

import type { Organization } from '@dreamshaper/ds-interface'

export const useOrganizationStore = defineStore('organizationStore', () => {
  const organizations = ref<Organization[] | undefined | null>()

  return {
    organizations,
  }
})
